import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"


function getYear() {
  return new Date().getFullYear();
}

export default function ThankYou({ data }) {
  return (
    <>
      <Layout>
        <SEO title="Ballteam Playlists" keywords={[`music`, `rap`, `hip-hop`, `playlists`, `spotify`, `apple music`, `austin`, `texas`]} />
        <a className="nav-link" href="/" style={{ marginBottom: '3rem', display: 'inline-block', width: 'auto' }}>Back to main page</a>

        <h1>Thanks for submitting.</h1>
      </Layout>
      <footer>
        <p className="small" style={{ textAlign: 'center' }}>&copy; Ballteam Playlists {getYear()}</p>
      </footer>
    </>
  )
}

// export const query = graphql`
//   {
//     logo: file(relativePath: { eq: "ballteam-white.png" }) {
//       childImageSharp {
//         fixed(width: 300) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     playlist1: file(relativePath: { eq: "rap-current.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 300) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     playlist2: file(relativePath: { eq: "texas-rap-current.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 300) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     playlist3: file(relativePath: { eq: "austin-rap-current.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 300) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     playlist4: file(relativePath: { eq: "austin-originals.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 300) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `